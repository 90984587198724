/**
 * Constructs the full URL using the base URL and the href.
 *
 * @param {Object} config - The state config object.
 * @param {string} href - the relative path.
 * @returns {string} The Full URL.
 */
export const getFullUrl = (config, href) => {
    if (!href) return '';
    const baseUrl = config.head.seo.baseUrl;
    return baseUrl + href;
};

/**
 * Calculates the product price based on the selling price amount and divisor.
 *
 * @param {Object} price - The Price object.
 * @returns {number} The calculated price.
 */
export const calculateProductPrice = (price = {}) => {
    const amount = price.sellingPrice?.amount;
    const divisor = price.sellingPrice?.divisor;

    return (amount / divisor).toFixed(2);
};

/**
 * Determines the variant naming schema for a product based on its buyable colours and SKUs.
 *
 * @param {Object} product - The primary product object for which the variant naming schema is being determined.
 * @returns {string} A string indicating the type of variant naming schema applicable to the product, based on the number of buyable colours and SKUs.
 */
export const getVariantNameSchema = (product) => {
    const buyableProductColours = product?.colourSwatches;

    if (buyableProductColours?.length > 1 && product?.sKUs?.length >= 1) {
        return 'multiColorsSizes';
    }
    if (buyableProductColours?.length === 1 && product?.sKUs?.length > 1) {
        return 'singleColorSizes';
    }

    return 'singleColorSize';
};

/**
 * Determines the appropriate schema properties for a product based on its variants.
 *
 * @param {Object} product - The primary product object for which the schema properties are being determined.
 * @returns {Array} An array of schema.org URLs that correspond to the product's variant properties, such as size and color.
 */
export const getVariantSchema = (product) => {
    const variantNameSchema = getVariantNameSchema(product);

    switch (variantNameSchema) {
        case 'multiColorsSizes':
            return ['https://schema.org/size', 'https://schema.org/color'];
        case 'singleColorSizes':
            return ['https://schema.org/size'];
        default:
            return [];
    }
};

/**
 * Generates a descriptive string for a product group, detailing available colours and sizes.
 *
 * @param {Object} product - The primary product object for which the group description is being generated.
 * @param {Object} messages - The messages object containing the translations for the current locale.
 * @returns {string} A descriptive string for the product group, highlighting the available colours, sizes, and material composition.
 */
export const getProductGroupDescriptionSchema = (product, messages) => {
    const productColours = product?.colourSwatches || '';
    const productName = product?.name;
    const composition = product?.sKUs[0]?.composition;
    const translation = messages?.seo?.pdpMarkup;

    const color1 = productColours[0]?.label?.toLowerCase();
    const color2 = productColours[1]?.label?.toLowerCase();

    const colorDescription = `${translation.nbColours({ copy: productColours.length, color1, color2 })}`;

    return `${productName}${colorDescription} ${composition}`.trim();
};

/**
 * Constructs a product description schema.
 * This function generates a descriptive string for a product SKU, incorporating details such as color, size, and material. It prioritizes the colour's editorial description if available; otherwise, it defaults to a generic description format.
 *
 * @param {Object} product - The primary product object.
 * @param {Object} sku - The specific SKU of the product being described.
 * @param {string} skuSize - The size of the SKU.
 * @param {Object} messages - The messages object containing the translations for the current locale.
 * @returns {string} A descriptive string for the product SKU, formatted for SEO optimization.
 */
export const getProductDescriptionSchema = (product, sku, skuSize, messages) => {
    const translation = messages?.seo?.pdpMarkup;
    const color = product?.label.toLowerCase();

    return translation?.productDescription({ name: product?.name, color, size: skuSize, material: sku?.composition });
};

/**
 * An object representing the different page types.
 *
 * @property {string} STANDARD - Represents a standard page type.
 * @property {string} DESIGNER - Represents a designer page type.
 * @property {string} MARK_DOWN - Represents a markdown page type.
 * @property {string} MARK_DOWN_DESIGNER - Represents a markdown designer page type.
 */

export const pageTypeKey = {
    STANDARD: 'STANDARD',
    DESIGNER: 'DESIGNER',
    MARK_DOWN: 'MARK_DOWN',
    MARK_DOWN_DESIGNER: 'MARK_DOWN_DESIGNER',
};

/**
 * Generates a name for the product listing page based on the metadata and page type.
 *
 * @param {Object} metaData - The metadata object for the current page, containing titles.
 * @param {Object} messages - The messages object containing the translations for the current locale.
 * @param {string} pageType - The current page type.
 * @param {Object} designerSaleToggleData - The object containing designer sale toggle data.
 * @returns {string} The name for the product listing page.
 */

export const getPLPName = (metaData, messages, pageType, designerSaleToggleData) => {
    const translation = messages?.seo?.plpMarkup;

    if (!metaData || !metaData.titles) {
        return;
    }

    if (pageTypeKey[pageType] === pageTypeKey.DESIGNER) {
        const titles = metaData?.titles;
        const designerName = titles[0];

        //  MRP - designerSaleToggleData and metaData with different values between localhost prod proxy and prod env
        return translation?.name({
            designerName: titles.length === 1 ? designerName : designerSaleToggleData?.label ? designerSaleToggleData?.label : titles[1],
            category: titles.length === 2 && designerSaleToggleData?.label !== titles[0] ? titles[0] : undefined,
        });
    }

    return metaData?.titles[0];
};

/**
 * Generates an image object for the product listing page.
 * This function constructs an image object for the product listing page, incorporating the primary and secondary images if available.
 * @param {Object} product - The product object for which the image object is being generated.
 * @returns {Array} An array of image objects for the product listing page.
 */

export const getPLPImage = (product) => {
    if (!product?.imgSrc) {
        return [];
    }

    const productHasSecondaryImage = product?.imgSrc?.secondary;

    if (productHasSecondaryImage) {
        return [
            {
                '@type': 'ImageObject',
                url: `https:${product?.imgSrc?.primary}`,
            },
            {
                '@type': 'ImageObject',
                url: `https:${product?.imgSrc?.secondary}`,
            },
        ];
    }

    return [
        {
            '@type': 'ImageObject',
            url: `https:${product?.imgSrc?.primary}`,
        },
    ];
};
